import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '../../common/ALink';
import {constructInitMarketingData} from '../../../utils/utils';

function SroreSelectSection() {
  const initMarketingData = constructInitMarketingData();

  return (
    <div className={'home-slide'}>
      <div className="home-slide-picture">
        <picture>
        <source
            media="(max-width: 390px)"
            srcSet="/images/general/hero-mobile.jpg"
          />
          <source
            media="(max-width: 1440px)"
            srcSet="/images/general/hero-image-1440.jpg"
          />
          <source
            media="(max-width: 1920px)"
            srcSet="/images/general/hero-image-1920.jpg"
          />
          <source
            media="(min-width: 1920px)"
            srcSet="/images/general/hero-image-2048.jpg"
          />
          <LazyLoadImage
            className="slide-bg"
            alt="Description of the image"
            src="/images/general/landWall.svg"
            height="auto"
            width="100%"
          />
        </picture>
      </div>
      <div className="home-content">
        <span className={'lrg-text'}>
          The nations’ leading provider in lease-to-own furniture and
          electronics
        </span>
        <span className={'scndr-text font4'}>
          Get Approved Instantly for up to <b>$5,000</b> in brand name furniture
          and electronics
        </span>
        <div className="btn-nav-block">
          <ALink
            href={`${process.env.FURNITURE_URL}/home`}
            className="btn btn-lg btn-dark mb-2 text-capitalize">
            Shop Furniture
          </ALink>
          <ALink
            href={{
              pathname: process.env.ELECTRONICS_URL,
              query: initMarketingData,
            }}
            className="btn btn-lg btn-dark text-capitalize">
            Shop Electronics
          </ALink>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SroreSelectSection);
