import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

Modal.setAppElement('#__next');

const customStyles = {
  content: {
    position: 'relative',
    maxWidth: '800px',
    width: '100%',
    padding: '3rem',
    marginLeft: '2rem',
    marginRight: '2rem',
    overflow: 'hidden auto',
    boxShadow: '0 10px 25px rgba(0,0,0,0.5)',
    maxHeight: 'calc( 100vh - 4rem )',
    fontSize: '16px'
  }
};

export const useLiveChat = () => {

  useEffect(() => {
    window.__lc = window.__lc || {};
    // window.__lc.license = 18104166;
    window.__lc.license = 11319597;
    // window.__lc.integration_name = 'manual_onboarding';
    window.__lc.integration_name = 'manual_channels';
    window.__lc.product_name = 'livechat';
    (function(n, t, c) {
      function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n);
      }
      var e = {
        _q: [],
        _h: null,
        _v: '2.0',
        on: function() {
          i(['on', c.call(arguments)]);
        },
        once: function() {
          i(['once', c.call(arguments)]);
        },
        off: function() {
          i(['off', c.call(arguments)]);
        },
        get: function() {
          if (!e._h)
            throw new Error(
              '[LiveChatWidget] You can\'t use getters before load.'
            );
          return i(['get', c.call(arguments)]);
        },
        call: function() {
          i(['call', c.call(arguments)]);
        },
        init: function() {
          var n = t.createElement('script');
          (n.async = !0),
          (n.type = 'text/javascript'),
          (n.src = 'https://cdn.livechatinc.com/tracking.js'),
          t.head.appendChild(n);
        }
      };
      !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
    })(window, document, [].slice);
  }, []);

  const LiveChatBtn = useMemo(() => {
    return () => (
      <div
        className="livechat_button"
        data-id="TVvwTFHq1LI"
      >
        <a
          href="https://www.livechat.com/customer-service/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_18104166"
        >
          Contact us
        </a>
      </div>
    );
  }, []);

  return [LiveChatBtn];
};
