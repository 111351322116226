import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Rating } from 'react-simple-star-rating';

function ReviewCard({ review }) {
  const { rating, text, img, name } = review;
  return (
    <div className="card-container">
      <div className="card-box">
        <Rating initialValue={rating} readonly size={20} />
        <span className="card-text font4">{text}</span>
        <div className="card-persone">
          <span
            alt="person"
            style={{
              backgroundColor: img.color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '43px',
              height: '43px',
              borderRadius: '50%',
              marginRight: '16px',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '16px'
            }}>
            {img.abbr}
          </span>
          <span className="card-name font4">{name}</span>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ReviewCard);
