import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '../../common/ALink';
import {constructInitMarketingData} from '../../../utils/utils';

function BestSellersSection() {
    const initMarketingData = constructInitMarketingData();

    return (
    <div className="best-container">
      <LazyLoadImage
        alt="electronics"
        src="images/best-electronic.png"
        className="best-absolute-img-left"
      />
      <div className="best-box">
        <h2 className="best-title font5">Our Best Sellers</h2>
        <p className="best-text font4">
          Browse our most popular products, at the lowest prices, from the last
          14 days
        </p>
        <div className="best-link-container">
          <ALink
            className="best-link font4"
            href={
              {
                pathname: process.env.ELECTRONICS_URL,
                query: initMarketingData,
              }}>
            Electronics
          </ALink>
          <span className="best-separator"></span>
          <ALink className="best-link font4" href={`/home`}>
            Furniture
          </ALink>
        </div>
      </div>
      <LazyLoadImage
        alt="furniture"
        src="images/best-furniture.png"
        className="best-absolute-img-right"
      />
    </div>
  );
}

export default React.memo(BestSellersSection);
