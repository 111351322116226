import React, { useState } from 'react';
import QuestionAccordion from './questionAccordion';
import ALink from '../../common/ALink';
import { useRouter } from 'next/router';
import { getFAQ } from '../../../utils/data/landing';

function FrequentlyQuestionsSection() {
  const [showAllQuestions, setShowAllQuestions] = useState(false);

  const router = useRouter();

  const updateQueryParams = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          modal: true
        }
      },
      undefined,
      { shallow: true }
    );
  };

  const questions = getFAQ(updateQueryParams);

  const visibleQuestions = showAllQuestions ? questions : questions.slice(0, 5);

  return (
    <div className="fr-question-container">
      <div className="fr-question-box">
        <h2 className="fr-question-title font5">Frequently asked questions</h2>
        <span className="fr-question-text">
          We`re happy to answer your questions
        </span>
      </div>
      <div className="fr-question-accs">
        {visibleQuestions.map(question => (
          <QuestionAccordion
            title={question.title}
            text={question.htmlContent}
          />
        ))}
      </div>
      {showAllQuestions ? (
        <a onClick={() => setShowAllQuestions(false)} className="ghost-btn">
          Hide
        </a>
      ) : (
        <a onClick={() => setShowAllQuestions(true)} className="ghost-btn">
          View All
        </a>
      )}
    </div>
  );
}

export default React.memo(FrequentlyQuestionsSection);
