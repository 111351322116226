import React, { useEffect, useState } from 'react';

import StoreSelectSection from '../components/partials/landing/storeSelectSection';
import AdvantageSection from '../components/partials/landing/advantageSection';
import AdditionalQuestionSection from '../components/partials/landing/additionalQuestionSection';
import FrequentlyQuestionSection from '../components/partials/landing/frequentlyQuestionSection';
import StoreNavSection from '../components/partials/landing/storeNavSection';
import StoreElectronicsNavSection from '../components/partials/landing/storeElectronicsNavSection';
import ReviewsSection from '../components/partials/landing/reviewsSection';
import BestSellersSection from '../components/partials/landing/bestSellersSection';
import FeaturedProductsSection from '../components/partials/landing/featuredProductsSection';
import Footer from '../components/partials/landing/footer';
import WorkDescriptionSection from '../components/partials/landing/workDescrSection';
import IframeModal from '../components/features/modals/iframe-modal';
import HowItWorks from '../components/features/modals/how-it-works';
import { HowItWorksStyles } from '../themes/iframe-modal';
import { useRouter } from 'next/router';
import FullheightIframe from '../components/features/modals/fullheight-iframe';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getLocalStorage, setLocalStorage } from '../utils/storage/storage';
import { defaultQueryParamsForShop } from '../components/constants';
import useApproveModalStore from '../store/approve-modal';

const uownSrc = process.env.NEXT_PUBLIC_UOWN_BASE_URL || '';

function Index() {
  const [leaseProvider, setLeaseProvider] = useState('uown'); // also on the checkout page
  const [isChecked, setIsChecked] = useState(false);
  const [isShowLeaseModal, setIsShowLeaseModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const { isOpen, closeModal, fromFooter } = useApproveModalStore();

  const router = useRouter();

  const {
    cartItems,
    cart_amount,
    shipping_cost,
    discount_amount
  } = useSelector(state => state.cart);

  const customerPhone = JSON.parse(localStorage.getItem('customerData'))
    ?.customer?.phone;

  const constructCurrentUownUrl = () => {
    const marketingData = JSON.parse(localStorage.getItem('marketingData'));
    let marketingDataQuery = '';
    if (marketingData?.pid)
      marketingDataQuery = marketingDataQuery.concat(
        `pid=${marketingData?.pid}`
      );
    if (marketingData?.sid)
      marketingDataQuery = marketingDataQuery.concat(
        `&sid=${marketingData?.sid}`
      );
    if (marketingData?.eid)
      marketingDataQuery = marketingDataQuery.concat(
        `&eid=${marketingData?.eid}`
      );
    if (marketingData?.oid)
      marketingDataQuery = marketingDataQuery.concat(
        `&oid=${marketingData?.oid}`
      );
    if (marketingData?.uid)
      marketingDataQuery = marketingDataQuery.concat(
        `&uid=${marketingData?.uid}`
      );
    if (marketingData?.gclid)
      marketingDataQuery = marketingDataQuery.concat(
        `&gclid=${marketingData?.gclid}`
      );

    const lineItems = {
      discountAmount: discount_amount,
      merchandiseSubtotal: Number(cart_amount - shipping_cost),
      orderTotal: Number(cart_amount),
      deliveryCharge: Number(shipping_cost),
      items: cartItems.map(item => ({
        productId: item.numberId,
        sku: item.sku,
        name: item.name.replace('&', 'and'),
        brand: item.name.replace('&', 'and'),
        quantity: item.qty,
        salePrice: item.price[0] || 0, // ?.toFixed(2),
        extendedSalePrice: Number(item.qty * item.price[0])
      }))
    };
    if (Cookies.get('DD_TOKEN') && !!customerPhone) {
      const uownUrl = `${uownSrc}/buyontrust/${customerPhone}/items?data=${JSON.stringify(
        lineItems
      )}&${marketingDataQuery}&store_type=furniture`;
      return uownUrl;
    }

    // console.log(`${uownSrc}/?${marketingDataQuery}`);
    return `${uownSrc}/?${marketingDataQuery}&store_type=furniture`;
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    closeModal();
    setLocalStorage('showHowItWorks', isChecked);
  };

  const handleCloseLeaseModal = () => {
    setIsShowLeaseModal(false);
    let newQuery = { ...router.query };
    let newQueryToString = '';
    if (new URLSearchParams(newQuery).toString().length) {
      newQueryToString = `?${new URLSearchParams(newQuery).toString()}`;
    } else {
      // Set default
      if (router.pathname === '/shop') {
        newQueryToString = defaultQueryParamsForShop; // '?search=&filters=%7B"categories"%3A%5B%5D%2C"price"%3A%7B"min"%3A0%2C"max"%3A0%7D%2C"materials"%3A%5B%5D%2C"colors"%3A%5B%5D%7D&page=1}';
      }
    }

    const newUrl = `${window.location.origin}${router.pathname}${newQueryToString}`;
    window.history.replaceState({}, '', newUrl);

    window.location.reload();
  };

  const handleOpenModal = () => {
    const showModal = getLocalStorage('showHowItWorks');
    if (fromFooter) {
      setIsOpenModal(true);
      return;
    }
    if (showModal === null || !showModal) {
      setIsOpenModal(true);
    } else {
      setIsShowLeaseModal(true);
    }
  };

  const customerData = localStorage.getItem('customerData');

  useEffect(() => {
    if (customerData) {
      const parsedData = JSON.parse(customerData);
      const leaseData = parsedData.lease_data || [];
      const approvedLease = leaseData.find(
        lease => lease?.name === 'uown_furniture' && lease?.application_status
      );
      if (approvedLease?.application_status === 'approved') {
        setIsApproved(true);
        setLocalStorage('showHowItWorks', isChecked);
      }
    }
  }, [customerData]);

  useEffect(() => {
    if (isOpen) handleOpenModal();
  }, [isOpen]);

  return (
    <main className={'skeleton-body skel-shop-products'}>
      <div>
        <StoreSelectSection />
        <AdvantageSection />
        <StoreNavSection />
        <WorkDescriptionSection id="how-it-works" />
        <StoreElectronicsNavSection />
        <FeaturedProductsSection />
        <BestSellersSection />
        <ReviewsSection />
        <FrequentlyQuestionSection />
        <AdditionalQuestionSection />

        <IframeModal
          isOpen={isOpenModal}
          onRequestClose={handleCloseModal}
          styles={HowItWorksStyles}>
          <HowItWorks
            handleClose={handleCloseModal}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            setIsOpenModal={setIsOpenModal}
            setIsShowLeaseModal={setIsShowLeaseModal}
          />
        </IframeModal>
        {leaseProvider === 'uown' && (
          <IframeModal
            isOpen={isShowLeaseModal}
            onRequestClose={handleCloseLeaseModal}>
            <FullheightIframe src={constructCurrentUownUrl()} />
          </IframeModal>
        )}
        {/*<a href="https://www.livechat.com/chat-with/18104166/" rel="nofollow">*/}
        {/*  Chat with us*/}
        {/*</a>*/}
        {/*, powered by{' '}*/}
        {/*<a*/}
        {/*  href="https://www.livechat.com/?welcome"*/}
        {/*  rel="noopener nofollow"*/}
        {/*  target="_blank">*/}
        {/*  LiveChat*/}
        {/*</a>*/}
        {/* <Footer/> */}
      </div>
    </main>
  );
}

export default Index;
