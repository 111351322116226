import React, { useState } from 'react';
import { Collapse } from 'react-collapse';

function QuestionAccordion({ title, text }) {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="acc-container">
      <div className="acc-box">
        <h3 className="acc-title font4">{title}</h3>
        <Collapse isOpened={isOpened}>
          <p className="acc-text">{text}</p>
        </Collapse>
      </div>
      <button
        className={`acc-btn ${isOpened ? 'acc-btn-open' : 'acc-btn-close'}`}
        onClick={() => setIsOpened(!isOpened)}> 
        <img width={18} height={18} src={!isOpened ? '/images/cross-open.svg': '/images/cross-close.svg'}  />
      </button>
    </div>
  );
}

export default React.memo(QuestionAccordion);
