import React from 'react';
import ALink from '../../common/ALink';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-box">
        <div className="footer-contacts">
          <ALink href={''}>
            <LazyLoadImage width={168} height={32} src="/images/logo.png" />
          </ALink>
          <p className="footer-contacts-text">
            The nations’ leading provider in lease-to-own furniture and
            electronics
          </p>
          <div className="footer-contacts-network">
            <ALink href={''} className={'footer-contacts-link'}>
              <LazyLoadImage src="/images/logo-twitter.svg" />
            </ALink>
            <ALink href={''} className={'footer-contacts-link'}>
              <LazyLoadImage src="/images/logo-fb.svg" />
            </ALink>
            <ALink href={''} className={'footer-contacts-link'}>
              <LazyLoadImage src="/images/logo-instagram.svg" />
            </ALink>
          </div>
        </div>
        <div className="footer-menu">
          <div className="footer-about">
            <h5 className="footer-about-title">About</h5>
            <div className="footer-about-links">
              <ALink href={''} className={'footer-about-link'}>
                How it works?
              </ALink>
              <ALink href={''} className={'footer-about-link'}>
                Featured
              </ALink>
              <ALink href={''} className={'footer-about-link'}>
                Contact us
              </ALink>
            </div>
          </div>
          <div className="footer-orders">
            <h5 className="footer-about-title">Accounts & Orders</h5>
            <div className="footer-orders-box">
              <div className="footer-orders-login">
                <ALink href={''} className={'footer-about-link'}>
                  Login or Sign Up
                </ALink>
                <ALink href={''} className={'footer-about-link'}>
                  Returns & Replacements
                </ALink>
                <ALink href={''} className={'footer-about-link'}>
                  Cancellations
                </ALink>
              </div>
              <div className="footer-orders-order">
                <ALink href={''} className={'footer-about-link'}>
                  Shipping options
                </ALink>
              </div>
            </div>
          </div>
          <ALink className={'up-arrow'} href={''}>
            <LazyLoadImage src="/images/up-arrow.svg" />
          </ALink>
        </div>
      </div>
      <div className="footer-copyright">
        <span className="copyright-text">
          ©2024 BuyOnTrust. All rights reserved
        </span>
        <div className="copyright-wrapper">
          <ALink href={''} className="copyright-text">
            Privacy & Policy
          </ALink>
          <ALink href={''} className="copyright-text">
            Terms & Condition
          </ALink>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
