import React from 'react';
import { useLiveChat } from '../../../hooks/useLiveChat';

function AdditionalQuestionsSection() {
  const [LiveChatBtn] = useLiveChat();

  return (
    <div className="add-question-container">
      <div className="add-question-box">
        <h2 className="add-question-title font5">
          Have an additional question?
        </h2>
        <span className="add-question-text font4">
          Chat live with us or leave us an after-hours message and we will get
          back to you by the next business day. You can also send us an email at
          <a
            href="mailto:info@buyontrust.com"
            style={{ marginLeft: '4px', color: '#4f4f4f', fontWeight: '600' }}>
            info@buyontrust.com
          </a>
        </span>

        <div className="add-question-btn font4">
          <a href="/contact-us">Contact us</a>
        </div>
      </div>
    </div>
  );
}

export default React.memo(AdditionalQuestionsSection);
